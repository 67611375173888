// var app = require('express')();
// var http = require('http').createServer(app);

// app.get('/', (req, res) => res.send('hello!'));
//   http.listen(3000, () => {
//   console.log('listening on *:3000');
// });
// io.on('connection', (socket) => {
//     console.log('a user connected');
//  });
//  http.listen(3000, () => {
//      console.log('listening');
//  })
$(document).ready(function() {
    const tagsList = ["/HMRKEKG", "/MPTJTNP"]
    const tagsLienNeodev = "/HMRKEKG";
    let pathname = document.location.pathname;
    if (pathname == tagsLienNeodev) {
        url = "https://drive.google.com/file/d/1--0OrJT9ltEzCwnNzcRLG-pAF2JGnzFE/view?pli=1";
        $(location).attr("href", url, 301);
    } else if (tagsList.includes(pathname)) {
        url = "https://viewer.chatbuilder.geoprod.com/e-constat-l5vg7ri";
        $(location).attr("href", url, 301);
    } else if (pathname == "/sj") {
        url = "https://foundme.com/sj";
        $(location).attr("href", url, 301);

    } else if (pathname == "/pj") {
        url = "https://foundme.com/pj";
        $(location).attr("href", url, 301);
    }
});
/* let pathname = document.location.pathname;
if (pathname == "/sj") {
    let a = document.createElement('a');
    a.target = '_blank';
    a.href = 'https://foundme.com/sj';
    a.click();
} else if (pathname == "/pj") {
    let a = document.createElement('a');
    a.target = '_blank';
    a.href = 'https://foundme.com/pj';
    a.click();
} */
